import { api } from '@redux/api/api'
import type { ISiteInfo } from '@reduxCommonApp/api/types/siteInfo'
import type { CurrenciesList, LanguagesList } from '@reduxCommonApp/types/commonAppState'
import type { AllExperimentsInfo, ExperimentConfig } from '@reduxCommonApp/types/experimentInfo'

import type { ConfigurationType } from '@Types/common/configurationBlocks'
import type { ConfigurationFrontendTypes } from '@constants/configuration/configuration'
import userSettingsStorage from '@storageServices/storageEntities/userSettings'

import commonUrls from '../constants/urls'

const commonAppApi = api.injectEndpoints({
  endpoints: build => ({
    getConfiguration: build.query<ConfigurationType, ConfigurationFrontendTypes>({
      keepUnusedDataFor: 600,
      query: (type: ConfigurationFrontendTypes) => ({ url: commonUrls.getConfiguration(type) }),
    }),
    getCurrencies: build.query<CurrenciesList, boolean | void>({
      keepUnusedDataFor: 0,
      query: visible => ({
        params: { visible },
        url: commonUrls.getCurrencies,
      }),
    }),
    getCurrencySwitch: build.query<void, string>({
      query: currency => ({ url: commonUrls.switchCurrency(currency) }),
    }),
    getEmailMisprints: build.query<string, string>({
      query: email => ({
        params: { email },
        url: commonUrls.getEmailMisprints,
      }),
    }),
    getExperimentInfo: build.query<AllExperimentsInfo, void>({
      query: () => ({ url: commonUrls.getExperimentInfo }),
    }),
    getFile: build.query<FileType, number>({
      query: fileId => ({ url: commonUrls.getFile(fileId) }),
    }),
    getLanguages: build.query<LanguagesList, void>({
      query: () => ({ url: commonUrls.getLanguages }),
    }),
    getSiteInfo: build.query<ISiteInfo, boolean>({
      // boolean argument true if server side, need for correct user_country_code
      query: () => ({
        url: commonUrls.getSiteInfo,
      }),
      transformResponse: (response: ISiteInfo) => {
        userSettingsStorage.addValues({
          currency: userSettingsStorage.getValue()?.currency || response.currency,
        })
        return response
      },
    }),
    postExperimentAssignment: build.query<ExperimentConfig | undefined, void>({
      query: () => ({
        body: {},
        method: 'POST',
        url: `${commonUrls.experimentAssignment}${typeof window !== 'undefined' ? document.location.search : ''}`,
      }),
      transformResponse: (response: [] | ExperimentConfig) => {
        return Array.isArray(response) ? undefined : response
      },
    }),
  }),
})

export const {
  endpoints: commonAppEndpoints,
  useGetConfigurationQuery,
  useGetCurrenciesQuery,
  useGetExperimentInfoQuery,
  useGetFileQuery,
  useGetLanguagesQuery,
  useGetSiteInfoQuery,
  useLazyGetEmailMisprintsQuery,
  useLazyPostExperimentAssignmentQuery,
  usePostExperimentAssignmentQuery,
} = commonAppApi

export const { getConfiguration, getCurrencies, getCurrencySwitch, getLanguages } = commonAppEndpoints
